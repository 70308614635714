import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
import TopAppBarEn from "../en/TopAppBar";
import {HoroscopeViewDayEn,HoroscopeViewTomorrowEn,HoroscopeViewYesterdayEn,
    HoroscopeViewWeekEn, HoroscopeViewMonthEn
 } from "../../components/HoroscopeView"



function HorosDay({sign}){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items_day, setItemsDay] = useState();
    useEffect(() => {
        const url_day = `https://goldfish-app-asx3v.ondigitalocean.app/dream/horoscope/en/today/${sign}`;
        fetch(url_day)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItemsDay(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }, [])
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else{
        return (
            <div>
                { (items_day !== null) &&
                    <HoroscopeViewDayEn 
                    date={items_day.data.date} 
                    horoscope_day={items_day.data.horoscope_data}>
                    </HoroscopeViewDayEn>
                }
            </div>
        )
    }
}
function HorosTomorrow({sign}){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items_tomorrow, setItemsTomorrow] = useState();
    useEffect(() => {
        const url_day = `https://goldfish-app-asx3v.ondigitalocean.app/dream/horoscope/en/tomorrow/${sign}`;
        fetch(url_day)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItemsTomorrow(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }, [])
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else{
        return (
            <div >
                { (items_tomorrow !== null) &&
                    <HoroscopeViewTomorrowEn 
                    date={items_tomorrow.data.date} 
                    horoscope_tomorrow={items_tomorrow.data.horoscope_data}>
                    </HoroscopeViewTomorrowEn>
                }
            </div>
        )
    }
}

function HorosYesterday({sign}){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items_yesterday, setItemsYesterday] = useState();
    useEffect(() => {
        const url_day = `https://goldfish-app-asx3v.ondigitalocean.app/dream/horoscope/en/yesterday/${sign}`;
        fetch(url_day)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItemsYesterday(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }, [])
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else{
        return (
            <div >
                { (items_yesterday !== null) &&
                    <HoroscopeViewYesterdayEn 
                    date={items_yesterday.data.date} 
                    horoscope_yesterday={items_yesterday.data.horoscope_data}>
                    </HoroscopeViewYesterdayEn>
                }
            </div>
        )
    }
}
function HorosWeek({sign}){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items_week, setItemsWeek] = useState();
    useEffect(() => {
        const url_day = `https://goldfish-app-asx3v.ondigitalocean.app/dream/horoscope/en/week/${sign}`;
        fetch(url_day)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItemsWeek(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }, [])
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else{
        return (
            <div >
                { (items_week !== null) &&
                    <HoroscopeViewWeekEn 
                    date={items_week.data.date} 
                    horoscope_week={items_week.data.horoscope_data}>
                    </HoroscopeViewWeekEn>
                }
            </div>
        )
    }
}
function HorosMonth({sign}){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items_month, setItemsMonth] = useState();
    useEffect(() => {
        const url_day = `https://goldfish-app-asx3v.ondigitalocean.app/dream/horoscope/en/month/${sign}`;
        fetch(url_day)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItemsMonth(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }, [])
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else{
        return (
            <div >
                { (items_month !== null) &&
                    <HoroscopeViewMonthEn 
                    date={items_month.data.date} 
                    horoscope_month={items_month.data.horoscope_data}>
                    </HoroscopeViewMonthEn>
                }
            </div>
        )
    }
}



function EnHoroscope(){
    let { sign } = useParams();
    return (
        <>
        <TopAppBarEn></TopAppBarEn>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Horoscope of your zodiac sign" />
          <meta property="og:description" content="Get detailed horoscope of the day, tommorow, yesterday, week and month of your zodiac sign."/>
        </Helmet>         
        <div className="text-base-content  pt-12  flex justify-center items-center  bg-base-100 mainDiv" >
            <div className="py-4 lg:py-6">
                <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
                    <h2 className="  flex justify-center items-center text-lg"><b>Horoscope of the zodiac sign </b>   </h2>
                    <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>{sign.charAt(0).toUpperCase()+sign.slice(1)}</b>   </h2>
                </div>
                
                <HorosDay sign={sign}></HorosDay>
                <HorosTomorrow sign={sign}></HorosTomorrow>
                <HorosYesterday sign={sign}></HorosYesterday>
                <HorosWeek sign={sign}></HorosWeek>
                <HorosMonth sign={sign}></HorosMonth>
            </div>
        </div>
        </>

    )
}

/*
function EnHoroscope() {
  let { sign } = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items_day, setItemsDay] = useState();
  const [items_tomorrow, setItemsTomorrow] = useState();
  const [items_yesterday, setItemsYesterday] = useState();

  useEffect(() => {
    
    const url_day = `https://goldfish-app-asx3v.ondigitalocean.app/dream/horoscope/en/today/${sign}`;
    fetch(url_day)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItemsDay(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );

      const url_tomorrow = `https://goldfish-app-asx3v.ondigitalocean.app/dream/horoscope/en/tomorrow/${sign}`;
      fetch(url_tomorrow)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItemsTomorrow(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );

        const url_yesterday = `https://goldfish-app-asx3v.ondigitalocean.app/dream/horoscope/en/yesterday/${sign}`;
        fetch(url_yesterday)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItemsYesterday(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
  }, [sign])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

  return (
    <>
    <TopAppBarEn></TopAppBarEn>
      <div className="text-base-content  pt-12  flex justify-center items-center  bg-base-100 mainDiv" >
          <div className="py-4 lg:py-6">
            <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
                <h2 className="  flex justify-center items-center text-lg"><b>Horoscope of the zodiac sign </b>   </h2>
                <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>{sign.charAt(0).toUpperCase()+sign.slice(1)}</b>   </h2>
            </div>
            <div className="text-base-content bg-base-100 px-4 py-6">
              
                            <div>
                            <HoroscopeViewDayEn 
                                date={items_day.data.date} 
                                horoscope_day={items_day.data.horoscope_data}>
                            </HoroscopeViewDayEn>
                            <HoroscopeViewTomorrowEn 
                                horoscope_tomorrow={items_tomorrow.data.horoscope_data}>
                            </HoroscopeViewTomorrowEn>
                            <HoroscopeViewYesterdayEn 
                                horoscope_yesterday={items_yesterday.data.horoscope_data}>
                            </HoroscopeViewYesterdayEn>
                        </div>
                    

                    
                                                                                                                                                                                               
            </div>
        </div>
      </div>
      
    </>
  );}
  }

*/



  
  export default  EnHoroscope;