import React from "react";
import {Helmet} from "react-helmet";
import TopAppBarFr from "./TopAppBarFr";
import aries from "../../assets/images/aries.jpg";
import taurus from "../../assets/images/taurus.jpg";
import gemini from "../../assets/images/gemini.jpg";
import cancer from "../../assets/images/cancer.jpg";
import leo from "../../assets/images/leo.jpg";
import virgo from "../../assets/images/virgo.jpg";
import libra from "../../assets/images/libra.jpg";
import scorpio from "../../assets/images/scorpio.jpg";
import sagittarius from "../../assets/images/sagittarius.jpg";
import capricorn from "../../assets/images/capricorn.jpg";
import aquarius from "../../assets/images/aquarius.jpg";
import pisces from "../../assets/images/pisces.jpg";

function HoroscopeFr() {
  return (
    <>
        <TopAppBarFr></TopAppBarFr>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Horoscope" />
          <meta property="og:description" content="Choisissez un signe zodiaque pour consulter l'horoscope du jour et de la semaine de ce signe."/>
        </Helmet>        
    {/**Description */}
    <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-12 mx-auto">
            <div className="flex flex-col text-justify w-full mb-12">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">&nbsp;</h1>
                <h2 className="text-black-500 dark:text-white">
                L'astrologie fait partie de la culture humaine depuis des milliers d'années et a influencé d'innombrables décisions et croyances. Parmi ses nombreux aspects, l'horoscope quotidien est l'aspect le plus connu et le plus utilisé de l'astrologie.
               <b> Pourquoi les gens lisent-ils leur horoscope quotidiennement et qu'est-ce que cela signifie pour eux ? </b> 
                Les horoscopes sont généralement lus pour se divertir.
                Les gens se tournent vers l'astrologie lorsqu'ils veulent s'occuper ou se détendre.
                Voici quelques raisons pour lesquelles vous devriez le lire quotidiennement :</h2><br /><br />

                <h2 className="text-black-500 dark:text-white font-bold">Pour être plus optimiste.</h2>
                <h2 className="text-black-500 dark:text-white">Vous ne pouvez pas forcer tout le monde à croire aux prédictions.
                Cependant, regarder les signes et apprendre de bonnes nouvelles peut changer les choses, surtout dans les mauvais moments. Lire l'horoscope peut aider les gens à rester optimistes malgré ce qu'ils traversent. Dans les moments difficiles, un peu d'espoir peut remonter le moral des gens et les empêcher d'abandonner.
                Si vous attendez un signe pour organiser vos pensées et faire les meilleurs choix, les horoscopes peuvent vous aider. Ils renforceront votre confiance en vous et vous guideront pour prendre des décisions judicieuses dans vos relations, votre carrière, vos études et d'autres aspects de la vie. Par conséquent, l'anticipation est la clé pour garder l'esprit ouvert et se préparer à ce qui va arriver.
                Être ouvert d'esprit et préparé vous facilitera la tâche plutôt que de ne pas savoir ce qui va se passer.</h2><br /><br />
                <h2 className="text-black-500 dark:text-white font-bold">Pour l'amélioration personnelle</h2>
                <h2 className="text-black-500 dark:text-white">Lorsque vous lisez votre horoscope, vous apprenez ce que l'astrologie a à dire sur les choses négatives de votre vie.
                Ces prédictions vous aideront à reconnaître vos erreurs et à les empêcher de conduire à des événements indésirables.
                Elles vous aideront également à découvrir les bonnes qualités que vous avez déjà en vous et à les utiliser comme des forces.
                Plus vous en apprendrez sur votre bonté et l'utiliserez pour vous améliorer, plus votre vie aura de sens.</h2><br /><br />
                <h2 className="text-black-500 dark:text-white font-bold">Évitez les malentendus dans les relations</h2>
                <h2 className="text-black-500 dark:text-white">Si vous prenez le temps de lire ce que l'astrologie a à dire sur votre relation avec votre partenaire, vous pouvez éviter de graves problèmes.
                En revanche, si vous n'êtes pas encore engagé envers quelqu'un, votre horoscope vous donnera des pensées positives sur votre vie amoureuse.</h2>
                <h2 className="text-purple-500 font-bold">Choisissez un signe zodiaque pour accéder à l'horoscope du jour.</h2>
                
            </div>
        </div>
    </section>
    {/**Topics */}
    <section className="text-gray-600 body-font dark:text-white">
        <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-wrap -m-4">
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                    <figure>
                            <img src={aries} alt="derivative" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar" src={aries} alt="integral" />
                            <h2 className="card-title"> Bélier</h2>
                            <a className="w-full btn bg-blue-700 hover:bg-blue-800 text-white btn-sm" href="/horoscope/fr/belier"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={taurus} alt="derivative" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar" src={taurus} alt="derivative" />
                            <h2 className="card-title">Taureau</h2>
                            <a className="w-full btn bg-purple-500 hover:bg-purple-800 text-white btn-sm" href="/horoscope/fr/taureau"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>

                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={gemini} alt="integral" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar" src={gemini} alt="integral" />
                            <h2 className="card-title"> Gémeaux</h2>
                            <a className="w-full btn bg-red-500 hover:bg-red-800 text-white btn-sm" href="/horoscope/fr/gemeaux"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>

    <section className="text-gray-600 body-font dark:text-white">
        <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-wrap -m-4">
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={cancer}  alt="slope" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar" src={cancer}  alt="slope" />
                            <h2 className="card-title">Cancer</h2>
                            <a className="w-full btn bg-blue-700 hover:bg-blue-800 text-white btn-sm" href="/horoscope/fr/cancer"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={leo} alt="ineq" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar"  src={leo} alt="ineq" />
                            <h2 className="card-title">Lion</h2>
                            <a className="w-full btn bg-purple-700  hover:bg-purple-800 text-white btn-sm" href="/horoscope/fr/lion"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={virgo} alt="ineq" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar"  src={virgo} alt="ineq" />
                            <h2 className="card-title">Vierge</h2>
                            <a className="w-full btn bg-red-500  hover:bg-red-800 text-white btn-sm" href="/horoscope/fr/vierge"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="text-gray-600 body-font dark:text-white">
        <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-wrap -m-4">
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={libra}  alt="slope" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar" src={libra}  alt="slope" />
                            <h2 className="card-title">Balance</h2>
                            <a className="w-full btn bg-blue-700 hover:bg-blue-800 text-white btn-sm" href="/horoscope/fr/balance"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img  src={scorpio} alt="ineq" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar" src={scorpio} alt="ineq" />
                            <h2 className="card-title">Scorpion</h2>
                            <a className="w-full btn bg-purple-700  hover:bg-purple-800 text-white btn-sm" href="/horoscope/fr/scorpion"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={sagittarius} alt="ineq" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar"  src={sagittarius} alt="ineq" />
                            <h2 className="card-title">Sagittaire</h2>
                            <a className="w-full btn bg-red-500  hover:bg-red-800 text-white btn-sm" href="/horoscope/fr/sagittaire"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="text-gray-600 body-font dark:text-white">
        <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-wrap -m-4">
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={capricorn}  alt="slope" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar" src={capricorn}  alt="slope" />
                            <h2 className="card-title">Capricorne</h2>
                            <a className="w-full btn bg-blue-700 hover:bg-blue-800 text-white btn-sm" href="/horoscope/fr/capricorne"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img src={aquarius} alt="ineq" />
                        </figure>
                        <div className="card-body flex items-center">
                            <img className="avatar" src={aquarius} alt="ineq" />
                            <h2 className="card-title">Verseau</h2>
                            <a className="w-full btn bg-purple-700  hover:bg-purple-800 text-white btn-sm" href="/horoscope/fr/verseau"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
                <div className="p-4 lg:w-1/3">
                    <div className="card shadow w-80 m-4">
                        <figure>
                            <img  src={pisces} alt="ineq" />
                        </figure>
                        <div className="card-body flex items-center">
                        <img className="avatar" src={pisces} alt="ineq" />
                            <h2 className="card-title">Poissons</h2>
                            <a className="w-full btn bg-red-700  hover:bg-red-800 text-white btn-sm" href="/horoscope/fr/poissons"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</>
  );
}
export default HoroscopeFr;